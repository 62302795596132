<template>
  <div class="eliteswitch-ui-detaillist eliteswitch-ui-box">

    <div class="eliteswitch-ui-detaillist--item">
      <div class="eliteswitch-ui-detaillist--item-label">Lijnen</div>
      <div class="eliteswitch-ui-badge">x</div>
    </div>
    <div class="eliteswitch-ui-detaillist--item">
      <div class="eliteswitch-ui-detaillist--item-label">Aantal te factureren</div>
      <div class="eliteswitch-ui-badge">x</div>
    </div>
    <div class="eliteswitch-ui-detaillist--item">
      <div class="eliteswitch-ui-detaillist--item-label">Totaalbedrag</div>
      <div class="eliteswitch-ui-badge">x</div>
    </div>
  </div>
</template>